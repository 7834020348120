import React, { useEffect, useRef } from 'react';
import { Link } from '@reach/router';
import Footer from '../components/footer';
import Counter from '../components/ImageGallery/components/Counter';
import '@fortawesome/fontawesome-free/css/all.min.css';

const AboutPage = () => {
  const elementsRef = useRef([]);

  useEffect(() => {
    const elements = elementsRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
            observer.unobserve(entry.target); // Stop observing once the animation is applied
          }
        });
      },
      { threshold: 0.1 }
    );

    elements.forEach((element, index) => {
      element.classList.add('hidden'); // Ensure elements are hidden initially
      observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  return (
    <div className='wraperitem'>
      <section className='jumbotron breadcumb' style={{ backgroundImage: `url(${'./img/testimony1.jpg'})`, backgroundPosition: 'center' }}>
        <div className='mainbreadcumb'>
          <div className='container-fluid'>
            <div className='row m-10-hor'>
              <div className='col-md-6' ref={(el) => (elementsRef.current[0] = el)}>
                <h1>About us</h1>
              </div>
              <div className='col-md-6' ref={(el) => (elementsRef.current[1] = el)}>
                <div className='list'>
                  <Link className='link' to="/">Home</Link>
                  <span className='dash'>/</span>
                  <span>About</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container-fluid black_more'>
        <div className='row m-10-hor'>
          <div className='col-md-5' ref={(el) => (elementsRef.current[2] = el)} style={{ display: 'flex', alignItems: 'center' }}>
            <div className='heading'>Who is the UHF?</div>
          </div>
          <div className='col-md-7' ref={(el) => (elementsRef.current[3] = el)}>
            <div className='content'>
              Established to serve humanity at local, national, and international levels. 
              The United Humanitarian Foundation focuses on alleviating poverty, creating economic opportunities, and delivering emergency relief. 
              UHF is a U.S. registered 501(c)(3), non-profit organization that provides humanitarian aid to those in need. 
            </div>
          </div>
        </div>
      </section>

      <section className='container-fluid text-center my-5' style={{ borderBottom: '1px solid #e0e0e0', borderTop: '1px solid #e0e0e0', padding: '50px 0' }}>
        <div className='row'>
          <div className='col-md-4' ref={(el) => (elementsRef.current[4] = el)}>
            <div className='icon-stats'>
            <i className="fas fa-users fa-3x"></i> {/* Icon for people helped */}
              <h3>People Helped</h3>
              <h2>
                <div className='counter' style={{ color: '#c7ab5a' }}><Counter end={1000} duration={3000} />+</div>
              </h2>
            </div>
          </div>
          <div className='col-md-4' ref={(el) => (elementsRef.current[5] = el)}>
            {/* projects */}
            <div className='icon-stats'>
              <i className="fas fa-project-diagram fa-3x"></i> {/* Icon for projects */}
              <h3>Projects</h3>
              <h2>
                <div className='counter' style={{ color: '#c7ab5a' }}><Counter end={11} duration={3000} isCurrency={false} /></div>
              </h2>
            </div>
          </div>
          <div className='col-md-4' ref={(el) => (elementsRef.current[6] = el)}>
            <div className='icon-stats'>
              <i className="fas fa-dollar-sign fa-3x"></i> {/* Icon for funds raised */}
              <h3>Funds Raised</h3>
              <h2>
                <div className='counter' style={{ color: '#c7ab5a' }}>
                  <Counter end={425000.00} duration={3000} isCurrency={true} />+
                </div>
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className='container-fluid text-center my-5' style={{ borderBottom: '1px solid #e0e0e0', padding: '50px 0', borderTop: '1px solid #e0e0e0'}}>
        <div className='row'>
          <div className='col-md-6 p-3' ref={(el) => (elementsRef.current[7] = el)}>
            <div className='icon-stats'>
              <i className="fas fa-file-pdf fa-3x"></i> 
              <h3>New United Humanitarian Foundation ByLaws</h3>
              <a href='https://docs.google.com/document/d/1jsBJbSom6ansxAvgME8jpkBG_rM_Nn6mbqGvyrfvrMY/mobilebasic'>
              <div style={{ color: '#c7ab5a', fontSize: '25px' }}>Link to Google Docs</div>
              </a>
            </div>
          </div>
          <div className='col-md-6 p-2' ref={(el) => (elementsRef.current[8] = el)}>
            <div className='icon-stats'>
              <i className="fas fa-file-pdf fa-3x"></i> 
              <h3>Financial Statement & Tax Filings for 2020, 2021, & 2022</h3>
              <a href='https://projects.propublica.org/nonprofits/organizations/843581349'>
              <div style={{ color: '#c7ab5a', fontSize: '25px' }}>Link to website</div>
              </a>
            </div>
          </div>
  
        </div>
      </section>


      <section className='container-fluid p-0'>
        <div className='row'>
          <div className="col-md-6 px-0" ref={(el) => (elementsRef.current[10] = el)} style={{ width: '100%', height: '500px'}}>
            <img src="./img/blog1.jpeg" className="imgslickz" alt="#" style={{ width: '100%', height: '100%', objectFit: 'cover'}}></img>
          </div>
          <div className="col-md-6 centered p-md-5 pt-5" ref={(el) => (elementsRef.current[11] = el)}>
            <div className="p-md-5" style={{textAlign:'center', margin: '20px'}}>
              <div className="heading">Our Vision</div>
              <p className="mt-3">
                UHF Relief envisions a world where communities are empowered, and all individuals have access to basic human needs. Together, we strive to bridge the gaps of inequality, ensuring that compassion and resources reach every corner of the globe.
              </p>
              <Link className='btn' to="/projects">
                <span className="shine" style={{ left: '-150px' }}></span>
                <span>Check out our projects!</span>
              </Link>
            </div>
          </div>
          <div className="col-md-6 centered p-md-5 pt-5 pb-5" ref={(el) => (elementsRef.current[12] = el)}>
            <div className="p-md-5" style={{textAlign:'center', margin: '20px'}}>
              <div className="heading">Our Mission</div>
              <p className="mt-3">
                Our mission is to deliver humanitarian relief, sustainable development, educational opportunities, and capacity-building essential services to communities through a dedicated and accountable team.
              </p>
              <Link className='btn' to="/donate">
                <span className="shine" style={{ left: '-150px' }}></span>
                <span>Donate to us!</span>
              </Link>
            </div>
          </div>
          <div className="col-md-6 px-0" ref={(el) => (elementsRef.current[13] = el)} style={{ width: '100%', height: '500px'}}>
            <img src="./img/blog2.jpeg" className="imgslickz" alt="#" style={{ width: '100%', height: '100%', objectFit: 'cover'}}></img>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default AboutPage;

