import React, { useEffect, useRef } from 'react';
import { Link } from '@reach/router';
import Footer from '../components/footer';
import '@fortawesome/fontawesome-free/css/all.min.css';

const JobListing = React.forwardRef(({ title, department, location, description, link }, ref) => (
  <div className="job-listing" ref={ref}>
    <h3>{title}</h3>
    <p><strong>Department:</strong> {department}</p>
    <p><strong>Location:</strong> {location}</p>
    <p>{description}</p>
    <a href={link} target="_blank" rel="noopener noreferrer" className="btn">
      <span className="shine"></span>
      <span>Apply Now</span>
    </a>
  </div>
));

const Jobs = () => {
  const elementsRef = useRef([]);

  useEffect(() => {
    const elements = elementsRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    elements.forEach((element) => {
      if (element) {
        element.classList.add('hidden');
        observer.observe(element);
      }
    });

    return () => {
      elements.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  const jobListings = [
    {
      title: "Event Coordinator",
      location: "Dearborn, MI",
      description: "The UHF Event Coordinator Application includes sections for personal and professional information, requires event planning, fundraising, and volunteer management experience, and seeks candidates capable of problem-solving during events, managing logistics, and handling financial administration, with responsibilities like sponsorship identification, event oversight, and travel as needed.",
      link: "https://forms.gle/Z7TQKhjxBUs1wTEy6"
    },
    {
      title: "Marketing Coordinator",
      location: "Dearborn, MI",
      description: "The UHF Marketing Coordinator Application involves creating, editing, and managing content across various platforms, developing marketing strategies, implementing SEO, collaborating with stakeholders, ensuring brand consistency, and supporting content-related projects and tasks.",
      link: "https://forms.gle/Df9RrseTWHKHJrXj7"
    },
    {
      title: "UHF Volunteer Coordinator",
      location: "Dearborn, MI",
      description: "The UHF Volunteer Coordinator Application involves developing volunteer engagement programs, managing recruitment and onboarding, maintaining accurate records, identifying new opportunities, providing education and training, organizing recognition events, and supporting staff in working with volunteers.",
      link: "https://docs.google.com/forms/d/e/1FAIpQLSevtUxQZ5JiQhTy6FhTZtHb6AEPDWFiH2VNUXm7CVDciVU67Q/viewform"
    },
    {
      title: "UHF Development Coordinator",
      location: "Dearborn, MI",
      description: "The UHF Development Coordinator Application involves building donor relationships, soliciting donations, managing fundraising events, ensuring pledge fulfillment, conducting donor outreach, responding to inquiries, delivering presentations to potential donors, and preparing reports for the board.",
      link: "https://docs.google.com/forms/d/e/1FAIpQLSet4KuxH6yEKdaawuh8R8Jolt1UWMt8Bt0x5Zzin5DEYgUliA/viewform?usp=send_form"
    },
    {
      title: "UHF Grant Coordinator",
      location: "Dearborn, MI",
      description: "The UHF Grant Coordinator Application involves researching potential funding sources, writing and submitting grant proposals, managing consultants, preparing funder reports, acting as a liaison for funding agencies, and engaging with funders to secure invitations for proposals.",
      link: "https://forms.gle/4ru5HjugNmhHd6VRA"
    },
        {
      title: "UHF Secretary",
      location: "Dearborn, MI",
      description: "The Secretary role involves scheduling meetings, taking minutes, managing communications, maintaining records, managing calendars, handling office organization, data entry, and supporting event planning.",
      link: "https://docs.google.com/forms/d/e/1FAIpQLSfIgJSlUt3aR4-mYQTj-m6POk-aVI-YmpdHV8W1gSHjLpa4dw/viewform"
    },
  ];

  return (
    <div className='wraperitem'>
      <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'./img/jobs.png'})`}}>
        <div className='mainbreadcumb'>
          <div className='container-fluid'>
            <div className='row m-10-hor'>
              <div className='col-md-6'>
                <h1>Careers</h1>
              </div>
              <div className='col-md-6'>
                <div className='list'>
                  <Link className='link' to="/">Home</Link>
                  <span className='dash'>/</span>
                  <span>Careers</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container-fluid' id="careers">
        <div className='row m-10-hor'>
          <div className='col-md-8'>
            <div ref={(el) => el && elementsRef.current.push(el)}>
              <h2>Join Our Team</h2>
              <p>At UHF Relief, we're always looking for passionate individuals who want to make a difference in the world. Our team is dedicated to alleviating poverty, creating economic opportunities, and delivering emergency relief to those in need. If you're committed to humanitarian work and want to be part of a dynamic, mission-driven organization, explore our current openings below.</p>
            </div>
            
            <div className="job-listings">
              {jobListings.map((job, index) => (
                <JobListing 
                  key={index} 
                  {...job} 
                  ref={(el) => el && elementsRef.current.push(el)}
                />
              ))}
            </div>
          </div>
          
          <div className='col-md-4'>
            <div className="sidebar" ref={(el) => el && elementsRef.current.push(el)}>
              <h3>Why Work With Us?</h3>
              <ul>
                <li>Make a real impact on communities in need</li>
                <li>Join a diverse and passionate team</li>
                <li>Opportunities for professional growth and development</li>
                <li>Competitive compensation and benefits</li>
                <li>Flexible work arrangements</li>
              </ul>
              <div className="cta-box">
                <h4>Don't see a role that fits?</h4>
                <p>We're always interested in hearing from talented individuals who share our mission. Send us your resume for future opportunities.</p>
                <Link to="/contact" className="btn">
                  <span className="shine"></span>
                  <span>Contact Us</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <style jsx>{`
        #careers {
          background-color: var(--black);
          color: var(--white);
        }
        .job-listings {
          display: grid;
          gap: 20px;
        }
        .job-listing {
          background-color: var(--black_more);
          border: 1px solid var(--gray);
          border-radius: 8px;
          padding: 20px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          transition: all 0.3s ease;
        }
        .job-listing:hover {
          transform: translateY(-5px);
          box-shadow: 0 4px 8px rgba(0,0,0,0.15);
        }
        .job-listing h3 {
          color: var(--color);
          margin-bottom: 10px;
        }
        .job-listing p {
          margin-bottom: 10px;
          color: var(--white);
        }
        .job-listing .btn {
          margin-top: 10px;
        }
        .sidebar {
          background-color: var(--black_soft);
          border: 1px solid var(--color);
          border-radius: 8px;
          padding: 20px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .sidebar h3 {
          color: var(--color);
          margin-bottom: 15px;
        }
        .sidebar ul {
          padding-left: 20px;
          margin-bottom: 20px;
          color: var(--white);
        }
        .sidebar li {
          margin-bottom: 10px;
        }
        .cta-box {
          background-color: var(--black_more);
          border-radius: 8px;
          padding: 15px;
          margin-top: 20px;
        }
        .cta-box h4 {
          color: var(--color);
          margin-bottom: 10px;
        }
        .cta-box p {
          color: var(--white);
        }
      `}</style>
    </div>
  );
};

export default Jobs;