export default [
  {
    src: "/img/gallery/0.jpg",
    alt: "food baskets",
    caption: "NEW! Food Baskets",
    width: 500,
    height: 500,
    description: "The UHF has initiated the Food Basket Project in Yemen, providing families with free, fresh, food every day. We supply the baskets with rice, beans, oil, etc.",
    slug: 'food_baskets'
  },
  {
    src:
      "/img/gallery/6.jpg",
    alt: "housing",
    caption: "Housing Project",
    width: 500,
    height: 500,
    description: "We have successfully built over 30 homes and counting, housing hundreds of people from the village of Hudaydah, Yemen.",
    slug:  'housing'
  },
  {
    src: "/img/gallery/1.jpg",
    alt: "bakery",
    caption: "Bakery Project",
    width: 500,
    height: 500,
    description: "The UHF has successfully initiated the Bakery Project in Yemen, providing families with free, fresh, bread every day. We supply the bakery with gas, flour, water, etc.",
    slug: 'bakery'
  },
  {
    src:
      "/img/gallery/9.jpg",
    alt: "water",
    caption: "Water Distribution",
    width: 500,
    height: 500,
    description: "Water is a humanitarian right. The lack of clean water is one of the main causes of Cholera and other water borne diseases. The UHF team delivers clean drinking water to areas in Yemen of high need. ",
    slug: 'water_distribution'
  },
  {
    src:
      "/img/gallery/10.jpg",
    alt: "kitchen",
    caption: "UHF Closet",
    width: 500,
    height: 500,
    description: "UHF Closet is a project that provides clothing to those in need. We have a location in Detroit, Michigan. We are always accepting donations.",
    slug: 'uhf_closet'
  },
  {
    src: "/img/gallery/5.jpg",
    alt: "eid",
    caption: "Eid Clothing",
    width: 500,
    height: 500,
    description: "We have the honor of putting smiles on unfortunate children’s faces every Eid, by providing them with new outfits. ",
    slug: 'eid_clothing'
  },
  {
    src:
      "/img/gallery/2.jpg",
    alt: "women",
    caption: "Women Empowerment",
    width: 500,
    height: 500,
    description: "Our goal at The UHF is to unite, give, and provide relief. As well as Empower. We've provided women with classes and materials to sew and make their own clothes with the Women’s Sewing Project.",
    slug: 'women_empowerment'
  },
  {
    src:
      "/img/gallery/3.jpg",
    alt: "covid",
    caption: "COVID Drives",
    width: 500,
    height: 500,
    description: "At the height of the COVID-19 Pandemic, the UHF team was on the scene, administering rapid testing and vaccines, with trained professionals. Necessities were distributed like hand sanitizer and masks. Join the team!",
    slug: 'covid_drives'
  },
  {
    src:
      "/img/gallery/4.jpg",
    alt: "food",
    caption: "Food Drives",
    width: 500,
    height: 500,
    description: "The UHF hosts food drives regularly that announced on Instagram.",
    slug: 'food_drives'
  },


  {
    src:
      "/img/gallery/7_.jpg",
    alt: "kitchen",
    caption: "Soup Kitchen",
    width: 500,
    height: 500,
    description: "The UHF has partnered with the Muslim Center of Detroit to assist withe the Soup Kitchen. Take the opportunity and volunteer there on Saturday's!",
    slug: 'soup_kitchen'
  },
  
  {
    src:
      "/img/gallery/8.jpg",
    alt: "literacy",
    caption: "Literacy Event",
    width: 500,
    height: 500,
    description: "Literacy events are a common scene with the UHF. Volunteers help organize books and pass them out to children as they enter. Help out at the next event and help the community!",
    slug: 'literacy_events'
  },
  {
    src:
      "/img/gallery/12udhiyah/6.JPG",
    alt: "UdhiyahQurbani",
    caption: "Udhiyah/Qurbani",
    width: 500,
    height: 500,
    description: "The UHF has the honor of giving you the opportunity to donate your Udhiyah/Qurbani to the less fortunate. We have a team that will distribute the meat to those in need. ",
    slug: 'udhiyah'
  }
];
