import React from 'react';

import '@fortawesome/fontawesome-free/css/all.min.css';


export default () => (
  <footer className='container-fluid black_more'>
    <div className='bg-footer'>
      <img src='/img/footer-img.jpg' alt='bg-footer'/>
    </div>
    <div className='row m-10-hor'>
      <div className='col-md-4'>
        <div className='footer-col'>
          <div className='heading'>
            <h2 className='font-weight-bold'>About Us</h2>
          </div>
          <div className='content'>
            <p>We work to create a world where individuals have access to basic human needs and communities are empowered.</p>
          </div>
          <div className="contact-info">
            <div className='info-item'>
              <i className="fas fa-envelope"></i>
              <a style={{ textDecoration: 'none', color: '#c8b16f' }} href="mailto:info@uhfrelief.org">
                info@uhfrelief.org
              </a>
            </div>
            <div className='info-item'>
              <i className="fas fa-phone-alt"></i>
              <a style={{ textDecoration: 'none', color: '#c8b16f' }} href="tel:3133324348">
                (313) 332-4348
              </a>
            </div>
            <div className='info-item'>
              <i className="fas fa-id-card"></i>
              TAX ID: 84-3581349
            </div>
            <div className='info-item'>
              <i className="fas fa-map-marker-alt"></i>
              14584 Southfield Rd Suite 200, Allen Park, MI 48101
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-2'>
        <div className='footer-col'>
          <div className='heading'>
            <h2 className='font-weight-bold'>Useful Links</h2>
          </div>
          <div className='content'>
            <div className='link'><a href="/donate">Donate</a></div>
            <div className='link'><a href="/contact">Contact Us</a></div>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='footer-col'>
          <div className='heading'>
            <h2 className='font-weight-bold'>Follow Us</h2>
          </div>
          <div className='content social-icons'>
            <a href="https://www.facebook.com/UHFRelief/" target="_blank" rel="noopener noreferrer">
              <div className='socialicon'>
                <i className="fab fa-facebook-f"></i>
              </div>
            </a>
            <a href="https://twitter.com/uhfrelief" target="_blank" rel="noopener noreferrer">
              <div className='socialicon'>
                <i className="fab fa-twitter"></i>
              </div>
            </a>
            <a href="https://www.instagram.com/uhfrelief/?hl=en" target="_blank" rel="noopener noreferrer">
              <div className='socialicon'>
                <i className="fab fa-instagram"></i>
              </div>
            </a>
            <a href="https://linktr.ee/uhfrelief" target="_blank" rel="noopener noreferrer">
              <div className='socialicon'>
                <i className="fa fa-link"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className='subfooter'>
      <div className='row m-10-hor'>
        <div className='col-md-6'>
          <div className='content'>© Copyrights 2023 <span className='font-weight-bold'>UHF</span> All rights reserved.</div>
        </div>
      </div>
    </div>
  </footer>
);
