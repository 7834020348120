import React, { useState } from 'react';
import { Link } from '@reach/router';
import Footer from '../components/footer';


export default function() {

  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
    user_reason: 'Questions'
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch('https://script.google.com/macros/s/AKfycbzuJg9I_L_2xEXNq9EAegp233F3bzHD1gUxoDjE73rEwrKz39XKCl6JZTvd9WNi5PDw/exec', {
      contentType: 'application/json',
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
  
    setFormData({
      user_name: '',
      user_email: '',
      user_phone: '',
      message: '',
      user_reason: ''
    });
    document.getElementById('success').classList.add('show');
  };

  return (
    <div>
    <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'./img/contact.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container-fluid'>
            <div className='row m-10-hor'>
              <div className='col-md-6'>
                <h1>Contact Us</h1>
              </div>
              <div className='col-md-6'>
                <div className='list'>
                  <Link className='link' to="/">Home</Link>
                  <span className='dash'>/</span>
                  <span>Contact Us</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='container-fluid black_more'>
        <div className='row m-10-hor'>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <div className="text-side">
                  <h2>Thanks for your interest!</h2>
                  <p>Please fill out the form below and we will get back to you as soon as possible in sha Allah. If you would like to contact us directly, please use the email or phone below.</p> 
                  <div className="address">
                    <div className="list">
                      <i className="fas fa fa-envelope-o"></i>
                      <a href="mailto:info@uhfrelief.org" target="_blank" rel="noopener noreferrer">
                        info@uhfrelief.org
                      </a>
                    </div>
                    <div className="list">
                      <i className="fas fa fa-phone"></i>
                      <a href="tel:313-332-4348" target="_blank" rel="noopener noreferrer">
                        (313) 332-4348
                      </a>
                    </div>
                    <div className="list">
                      <i className="fas fa-id-card"></i>
                      <span href="" target="_blank" rel="noopener noreferrer">
                        TAX ID 84-3581349
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <i className="fa fa-quote-left fa2"></i>
                  <div className="text">
                    <i className="fas fa fa-quote-right fa1"></i>
                    <div>
                      <h2>Quran 76:9</h2>
                      <p>"We feed you only for the sake of Allah, seeking neither reward nor thanks from you."</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-6'>
            <div className="form-side">
              <form className="formcontact" onSubmit={handleSubmit}>
                <label>Full Name (required)</label>
                <input type="text" name="user_name" value={formData.user_name} onChange={handleChange} required />

                <label>Phone Number (required)</label>
                <input type="text" name="user_phone" value={formData.user_phone} onChange={handleChange} required />
                
                <label>Email (required)</label>
                <input type="email" name="user_email" value={formData.user_email} onChange={handleChange} required />

                <label>Why are you reaching out? (required)</label>
                <select name="user_reason" value={formData.user_reason} onChange={handleChange} required>
                  <option value="questions">Questions</option>
                  <option value="volunteer">Volunteer</option>
                  <option value="sponsorship">Sponsorship</option>
                  <option value="vendor">Vendor</option>
                  <option value="other">Other</option>
                </select>
                
                <label>Message (required)</label>
                <textarea name="message" value={formData.message} onChange={handleChange} required />
                
                <div id='success' className='hide'>Your message has been sent...</div>
                <div id='failed' className='hide'>Message failed...</div>
                
                <button type="submit" id='buttonsent'>
                  <span className="shine"></span>
                  <span>Send</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      
      </section>
      <Footer />
    </div>
  );
}
