
import React, { Component } from 'react';

export default

class CustomSlide extends Component {
    render() {
      const { index, imgSrc, ...props } = this.props;
      return (
        <div {...props}>
          <div className="img-container" style={{ width: '100%', paddingTop: '75%', position: 'relative' }}>
            <img 
              src={imgSrc} 
              className="img-fluid" 
              alt={`Slide ${index}`} 
              style={{ 
                position: 'absolute',
                top: 0,
                left: 0,
                width: '98%',
                height: '100%',
                objectFit: 'cover'
              }} 
            />
          </div>
        </div>
      );
    }
  }

