import React, { useEffect, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { header } from 'react-bootstrap';
import { Link } from '@reach/router';
import useOnclickOutside from "react-cool-onclickoutside";


setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);

const NavLink = props => (
  <Link 
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);

// Type writer effect
const useTypewriter = (words, speed = 50, delayBetweenWords = 1000) => {
  const [displayText, setDisplayText] = useState('');
  const [wordIndex, setWordIndex] = useState(0);

  useEffect(() => {
    if (!words || words.length === 0) return; // Early return if `words` is undefined or empty
    const currentWord = words[wordIndex];
    let i = 0;
    setDisplayText('');

    const typingInterval = setInterval(() => {
      if (i < currentWord.length) {
        setDisplayText(prevText => prevText + currentWord.charAt(i));
        i++;
      } else {
        clearInterval(typingInterval);
        setTimeout(() => {
          setWordIndex(prevIndex => (prevIndex + 1) % words.length);
        }, delayBetweenWords);
      }
    }, speed);

    return () => {
      clearInterval(typingInterval);
    };
  }, [wordIndex, words, speed, delayBetweenWords]);

  return displayText;
};

const words = ['Contact', 'Volunteer', 'Sponsor'];

const Typewriter = ({ words, speed }) => {
  const displayText = useTypewriter(words, speed);
  return <>{displayText}</>;
};



export default function() {

    const [, setOpenMenu] = React.useState(false);
    const [, ] = React.useState(false);
    const closeMenu = () => {
      setOpenMenu(false);
    };
    const ref = useOnclickOutside(() => {
      closeMenu();
    });

    const [showmenu, btn_icon] = useState(false);
    useEffect(() => {
    const header = document.getElementById("myHeader");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
        btn_icon(false);
        if (window.pageYOffset > sticky + 300) {
          header.classList.add("sticky");
          
        } else {
          header.classList.remove("sticky");
        } if (window.pageYOffset > sticky) {
          closeMenu();
        }
      });
      return () => {
        window.removeEventListener("scroll", scrollCallBack);
      };
    }, []);
    return (
    <header id="myHeader" className='navbar'>
     <div className='container-fluid'>
       <div className='row m-2-hor w-100-nav'>
          <div className='logo'>
              <div className='navbar-title navbar-item'>
                <NavLink to="/">
                <img
                    src="/img/logo_t_light.png"
                    className="img-fluid"
                    alt="#"
                  />
                </NavLink>
              </div>
          </div>
                    
              <BreakpointProvider>
                <Breakpoint l down>
                  {showmenu && 
                  <div className='menu'>
                    <div className='navbar-item'>
                      <div ref={ref}>
                        <NavLink to="/" onClick={() => btn_icon(!showmenu)}>
                          Home
                        </NavLink>
                      </div>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/about" onClick={() => btn_icon(!showmenu)}>
                        About Us
                      </NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/team" onClick={() => btn_icon(!showmenu)}>
                        Team
                      </NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/events" onClick={() => btn_icon(!showmenu)}>
                        Events
                      </NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/projects" onClick={() => btn_icon(!showmenu)}>
                        Projects
                      </NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/shop" onClick={() => btn_icon(!showmenu)}>
                        Shop
                      </NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/jobs" onClick={() => btn_icon(!showmenu)}>
                        Jobs
                      </NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/contact" onClick={() => btn_icon(!showmenu)}>
                        <Typewriter words={words} speed={100} />
                      </NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/donate" onClick={() => btn_icon(!showmenu)}>
                        Donate
                      </NavLink>
                    </div>
                  </div>
                  }
                </Breakpoint>

                <Breakpoint xl>
                  <div className='menu'>
                    <div className='navbar-item'>
                        <div ref={ref}>
                          <NavLink to="/">
                            Home
                          </NavLink>
                        </div>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/about">About Us</NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/team">Team</NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/events">Events</NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/projects">Projects</NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/shop">Shop</NavLink>
                    </div>
                    <div className='navbar-item' style={{ paddingRight: '20px' }}>
                      <NavLink to="/jobs">Jobs</NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/contact">
                        <span className="contact-button" role="button"><Typewriter words={words} speed={100} /></span>
                      </NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/donate">
                        <span className="donate-button" role="button">Donate</span>
                      </NavLink>
                    </div>
                  </div>
                </Breakpoint>
              </BreakpointProvider>
                  
      </div>

        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>

      </div>     
    </header>
    );
}
