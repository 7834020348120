import React, { useEffect } from 'react';
import { getAnalytics, logEvent } from "firebase/analytics";
import PosedRouter from './components/PosedRouter.js'
import Header from './menu/header';
import Home from './pages/home';
import About from './pages/about';
import Team from './pages/team';
import Donate from './pages/donate';
import Projects from './pages/projects';
import Events from './pages/events';
import Jobs from './pages/jobs';
import ShoppingCartApp from './pages/shop';
import Contact from './pages/contact';
import FoodBaskets from './pages/projects/food_baskets';
import Bakery from './pages/projects/bakery';
import Women from './pages/projects/women_empowerment';
import Covid from './pages/projects/covid_drives';
import Food from './pages/projects/food_drives';
import Housing from './pages/projects/housing_project';
import Eid from './pages/projects/eid_clothing';
import Literacy from './pages/projects/literacy_events';
import Water from './pages/projects/water_distribution';
import Soup from './pages/projects/soup_kitchen';
import Closet from './pages/projects/uhf_closet';
import Zakat from './pages/zakat';
import Test from './pages/test';
import Udhiyah from './pages/projects/udhiyah.js';

// 404 page
import NotFound from './pages/404.js';

import HopeForYemen from './pages/events/hopeforyemen';
import HitsForHumanity from './pages/events/hitsforhumanity';


import { firebaseApp } from '../components/components/firebase';

function usePageViews() {
  useEffect(() => {
    const analytics = getAnalytics(firebaseApp); // Ensure firebaseApp is correctly initialized
    logEvent(analytics, 'page_view', {
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_title: document.title    });
  }, []);
}

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

export default () => {
  usePageViews();

  return (
    <div className="wraper">
      <Header/>
      <PosedRouter>
        <ScrollTop path="/">
          <Home path="/" />
          <About path="/about" />
          <Team path="/team" />
          <Donate path="/donate" />
          <Projects path="/projects" />
          <Events path="/events" />
          <Jobs path="/jobs" />
          <ShoppingCartApp path="/shop" />
          <Contact path="/contact" />
          <FoodBaskets path="/projects/food_baskets" />
          <Bakery path="/projects/bakery" />
          <Women path="/projects/women_empowerment" />
          <Covid path="/projects/covid_drives" />
          <Food path="/projects/food_drives" />
          <Housing path="/projects/housing" />
          <Eid path="/projects/eid_clothing" />
          <Literacy path="/projects/literacy_events" />
          <Water path="/projects/water_distribution" />
          <Soup path="/projects/soup_kitchen" />
          <Closet path="/projects/uhf_closet" />
          <Zakat path="/zakat_alfitr" />
          <HopeForYemen path="/events/hopeforyemen" />
          <HitsForHumanity path="/events/hitsforhumanity" />
          <Udhiyah path="/projects/udhiyah" />
          <Test path="/private/test" />
          <NotFound default />
        </ScrollTop>
      </PosedRouter>
    </div>
  );
};