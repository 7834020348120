import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCFfmye7COX0DaanVqgt8vKLZJGvbyBz80",
  authDomain: "uhfrelief.firebaseapp.com",
  projectId: "uhfrelief",
  storageBucket: "uhfrelief.appspot.com",
  messagingSenderId: "830879865541",
  appId: "1:830879865541:web:7d6031355a5d10fe7a2eac"
};
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const analytics = getAnalytics(firebaseApp);
//const auth = getAuth(firebaseApp);

export { db, collection, addDoc, getAuth, analytics, firebaseApp };