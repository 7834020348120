import React from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';


const content = [
  {
    title: "LAUNCHGOOD CAMPAIGNS!",
    description:
      "We need your help to provide provide education for people in yemen",
    button: "Donate",
    link: "https://www.launchgood.com/v4/campaign/help_support_education_for_children_in_yemen?src=562641",
    image: "./img/main.jpg"
  },
  {
    title: "LAUNCHGOOD CAMPAIGNS!",
    description:
      "Consider donating to provide food for people in yemen",
    button: "Donate",
    link: "https://www.launchgood.com/v4/campaign/stop_starvation_donate_today_to_support_yemeni_families?src=562641",
    image: "./img/bakery.jpeg"
  },
  // {
  //   title: "UNITE.",
  //   description:
  //     "Bring communities together for a common purpose",
  //   button: "null",
  //   link: "/#",
  //   image: "./img/bg-1.png"
  // },
  // {
  //   title: "GIVE.",
  //   description:
  //     "Offer hope, resources, and a helping hand to those in need",
  //   button: "null",
  //   link: "/#",
  //   image: "./img/bg-2.jpg"
  // },
  // {
  //   title: "RELIEF.",
  //   description:
  //     "Provide aid where it matters most, transforming lives",
  //   button: "null",
  //   link: "/#",
  //   image: "./img/bg-3.jpeg"
  // },
];

export default () => {



return (
    <Slider className="slider-wrapper" autoplay={5000} touchDisabled={true}>
      {content.map((item, index) => (
        <div
          key={index}
          className="slider-content"
          style={{ background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${item.image}') no-repeat center center` }}
        >
          <div className="inner">
            <h1>{item.title}</h1>
            <p>{item.description}</p>
            { 
            item.button !== "null" && <button onClick={()=> window.open(item.link, "_self")}>
              <div>
                <span className="shine"></span>
                <h7 className="text">{item.button}</h7>
              </div>
            </button>
            }
          </div>
        </div>
      ))}
    </Slider>
  );
}
