import React, { Component, useEffect } from 'react';
import { Link } from '@reach/router';
import Slider from 'react-slick';
import Footer from '../../components/footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomSlide from '../../components/ImageGallery/slides';

class Images extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 5000,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    };

    const imageNames = [
      '2.JPG',
      '3.JPG',
      '4.JPG',
      '5.JPG',
      '6.JPG',
      '7.JPG',
      '8.JPG',
      '9.JPG',
      '10.JPG',
    ];

    return (
      <div className='slickteam'>
        <Slider {...settings}>
          {imageNames.map((imageName, index) => (
            <CustomSlide
              className='itm'
              index={index}
              key={index}
              imgSrc={`/img/gallery/12udhiyah/${imageName}`}
            />
          ))}
        </Slider>
      </div>
    );
  }
}

export default () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://prod-donation-elements-b-donationelementsjsfilesb-1m4f4dl6p6b21.s3.us-east-2.amazonaws.com/bundle.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  
  return (

    <div className='wrapperitem'>
      <section className='jumbotron breadcumb' style={{ position: 'relative', overflow: 'hidden', zIndex: 0 }}>
        <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/gallery/12udhiyah/1.JPG)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'blur(3px)',
            zIndex: -2,
          }}>
        </div>
        <div className='mainbreadcumb' style={{ position: 'relative', zIndex: 1 }}>
          <div className='container-fluid'>
            <div className='row m-10-hor'>
              <div className='col-md-6'>
                <h1>Udhiyah<br></br>Qurbani</h1>
              </div>
              <div className='col-md-6'>
                <div className='list'>
                  <Link className='link' to="/">Home</Link>
                  <span className='dash'>/</span>
                  <span>Udhiyah/Qurbani</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container-fluid' id="service">

        
        <div className='row m-10-hor'>
        {/* <div className='col-md-12'>
          <div className='content'>
          Performing Qurbani through UHF ensures your donation reaches those most in need, providing vital nutrition to vulnerable communities. By choosing us, you fulfill a religious duty while supporting efforts to combat hunger and poverty efficiently and ethically. Inshallah through this we can make a meaningful difference in the lives of people in need.
          </div>
        </div> */}
        {/* <div style={{ width: '100%', height: '1px', backgroundColor: '#FFF', margin: '10px 0' }}></div> */}
          <div className='col-md-4'>
            <h3 style={{
              // ceneter
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              
            }}>Donate your Qurbani!</h3>
            <div data-entity="EMBED_FORM" data-source="CharityStack" id="89b174e8-91c8-448c-b55e-8dfda6205d1f"></div>
          </div>
          <div className='col-md-8'>
            <Images />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};