import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 5000,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className='slickteam'>
        <Slider {...settings}>

          <CustomSlide className='itm' index={1}>
            <div className='bg'>
              <img
                src="./img/team/eman_ali_ahmed.jpeg"
                className="img-fluid"
                alt="Imageteam"
                style={{ width: '400px', height: '400px', objectFit: 'cover' }}
              />
            </div>
            {/* <div className='hover-text'>Eman Ali-Ahmed Ed.S., a seasoned educator and administrator, has dedicated her life to promoting educational excellence.</div> */}
            <div className='desc'>
              <div className='name'>Eman Ali-Ahmed</div>
              <div className='title'>Executive Director</div>
            </div>
          </CustomSlide>

         

          <CustomSlide className='itm' index={2}>
            <div className='bg'>
              <img
                src="./img/team/sara_alduais.png"
                className="img-fluid"
                alt="Imageteam"
                style={{ width: '400px', height: '400px', objectFit: 'cover' }}

              />
            </div>
            <div className='desc'>
              <div className='name'>Sara Alduais</div>
              <div className='title'>Vice President</div>
            </div>
          </CustomSlide>

         
          <CustomSlide className='itm' index={3}>
            <div className='bg'>
              <img
                src="./img/team/wardah_jaffar.jpg"
                className="img-fluid"
                alt="Imageteam"
                style={{ width: '400px', height: '400px', objectFit: 'cover' }}

              />
            </div>
            <div className='desc'>
              <div className='name'>Wardah Jaffar</div>
              <div className='title'>Financial Manager</div>
            </div>
          </CustomSlide>
          
          <CustomSlide className='itm' index={4}>
            <div className='bg'>
              <img
                src="./img/team/seliman_ali.png"
                className="img-fluid"
                alt="Imageteam"
                style={{ width: '400px', height: '400px', objectFit: 'cover' }}
              />
            </div>
            <div className='desc'>
              <div className='name'>Seliman Ali</div>
              <div className='title'>PR Manager</div>
            </div>
          </CustomSlide>
         
          <CustomSlide className='itm' index={5}>
            <div className='bg'>
              <img
                src="./img/team/hassan_essa_kadiri.jpg"
                className="img-fluid"
                alt="Imageteam"
                style={{ width: '400px', height: '400px', objectFit: 'cover' }}
              />
            </div>
            <div className='desc'>
              <div className='name'>Hassan Kadiri</div>
              <div className='title'>Website Manager</div>
            </div>
          </CustomSlide>

          <CustomSlide className='itm' index={6}>
            <div className='bg'>
              <img
                src="./img/team/azmi_alzandani.jpg"
                className="img-fluid"
                alt="Imageteam"
                style={{ width: '400px', height: '400px', objectFit: 'cover' }}
              />
            </div>
            <div className='desc'>
              <div className='name'>Azmi Alzandani</div>
              <div className='title'>Board Member</div>
            </div>
          </CustomSlide>

          <CustomSlide className='itm' index={7}>
            <div className='bg'>
              <img
                src="./img/team/suhaib_alanooti.jpg"
                className="img-fluid"
                alt="Imageteam"
                style={{ width: '400px', height: '400px', objectFit: 'cover' }}
              />
            </div>
            <div className='desc'>
              <div className='name'>Suhaib Alanooti</div>
              <div className='title'>Board Member</div>
            </div>
          </CustomSlide>

          <CustomSlide className='itm' index={8}>
            <div className='bg'>
              <img
                src="./img/team/mohammad_ali_itani.png"
                className="img-fluid"
                alt="Imageteam"
                style={{ width: '400px', height: '400px', objectFit: 'cover' }}

              />
            </div>
            <div className='desc' style={{ color: 'black' }}>
              <div className='name'>Mohamed Itani</div>
              <div className='title'>Board Member</div>
            </div>
          </CustomSlide>

          <CustomSlide className='itm' index={9}>
            <div className='bg'>
              <img
                src="./img/team/heba_fardoun.png"
                className="img-fluid"
                alt="Imageteam"
                style={{ width: '400px', height: '400px', objectFit: 'cover' }}
              />
            </div>
            <div className='desc'>
              <div className='name'>Heba Fardoun</div>
              <div className='title'>Board Member</div>
            </div>
          </CustomSlide>

         
        </Slider>
      </div>
    );
  }
}


