import React, { useEffect } from 'react';
import { Router, Location } from '@reach/router';
import Loader from '../menu/loader';

const PageWrapper = ({ children, location }) => {
  useEffect(() => {
    
  }, [location]);

  return (
    <div id='routerhang'>
      <div key={location.key}>
        <Loader />
        {children}
      </div>
    </div>
  );
};

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <PageWrapper location={location}>
        <Router location={location}>
          {children}
        </Router>
      </PageWrapper>
    )}
  </Location>
);

export default PosedRouter;
