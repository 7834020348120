import React, { useState } from 'react';

const CheckoutModal = ({ isOpen, onClose, onCheckout }) => {
  const [formData, setFormData] = useState({
    name: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    phoneNumber: '',
    email: '',
  });

  // Styles for modal backdrop
const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };
  
  const modalContentStyle = {
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      maxWidth: '500px',
      margin: '0 auto', 
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };
  
  const submitButtonStyle = {
      backgroundColor: '#4CAF50', 
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      padding: '10px 20px',
      cursor: 'pointer',
      fontWeight: 'bold',
  };
  
  // Styles for cancel button
  const cancelButtonStyle = {
      backgroundColor: '#f44336',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      padding: '10px 20px',
      cursor: 'pointer',
      fontWeight: 'bold',
      marginTop: '10px',
  };
  
  const formStyle = {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      minWidth: '300px',
  };

  if (!isOpen) return null;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onCheckout(formData);
    onClose(); // Close modal after checkout
  };

  return (
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <h2 style={{color: 'black'}}>Checkout</h2>
        <form onSubmit={handleSubmit} style={formStyle}>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
          />
          <input
            type="text"
            name="street"
            placeholder="Street"
            value={formData.street}
            onChange={handleChange}
            required
            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
          />
          <input 
            type="text"
            name="city"
            placeholder="City"
            value={formData.city}
            onChange={handleChange}
            required
            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
          />
          <input 
            type="text"
            name="state"
            placeholder="State"
            value={formData.state}
            onChange={handleChange}
            required
            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
          />
          <input 
            type="text"
            name="zip"
            placeholder="Zip"
            value={formData.zip}
            onChange={handleChange}
            required
            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
          />
          <input
            type="tel"
            name="phoneNumber"
            placeholder="Phone Number"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
          />
          <button type="submit" style={submitButtonStyle}>Submit</button>
          <button type="button" onClick={onClose} style={cancelButtonStyle}>Cancel</button>
        </form>
      </div>
    </div>
  );
};



export default CheckoutModal;
