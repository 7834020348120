import React, { useEffect, useRef } from 'react';
import { Link } from '@reach/router';
import ImageGallery from "../components/ImageGallery";
import Footer from '../components/footer';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Projects = () => {
  const elementsRef = useRef([]);

  useEffect(() => {
    const elements = elementsRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
            observer.unobserve(entry.target); // Stop observing once the animation is applied
          }
        });
      },
      { threshold: 0.1 }
    );

    elements.forEach((element) => {
      if (element) {
        element.classList.add('hidden'); // Ensure elements are hidden initially
        observer.observe(element);
      }
    });

    return () => {
      elements.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return (
    <div>
      <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'/img/projects.jpg'})`}} ref={(el) => (elementsRef.current[0] = el)}>
        <div className='mainbreadcumb'>
          <div className='container-fluid'>
            <div className='row m-10-hor'>
              <div className='col-md-6'>
                <h1>Projects</h1>
              </div>
              <div className='col-md-6'>
                <div className='list'>
                  <Link className='link' to="/">Home</Link>
                  <span className='dash'>/</span>
                  <span>Projects</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container-fluid black' ref={(el) => (elementsRef.current[1] = el)}>
        <div className='row m-10-hor'>
          <div className='col-md-5'>
            <div className='heading'>
              Projects that<span className='br'></span> Serve Humanity
            </div>
          </div>
          <div className='col-md-7'>
            <div className='content'>
              Our Projects section highlights the heart and soul of our humanitarian efforts. We are committed to improving the lives of the underprivileged, one project at a time. From organizing extensive food drives and building sustainable housing to providing clean water access in remote areas, each initiative represents our unwavering commitment to making a difference. Scroll through to see how we are turning compassion into action, and how you can contribute to these life-changing endeavors.
            </div>
          </div>
        </div>
      </section>

      <section className='container-fluid black_more' ref={(el) => (elementsRef.current[2] = el)}>
        <div className='row m-10-hor'>
          <div className='col-12'>
            <div className='AppContainer'>
              <ImageGallery />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Projects;
