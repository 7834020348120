import React, { useEffect } from 'react';
import Footer from '../components/footer';

const Donate = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://prod-donation-elements-b-donationelementsjsfilesb-1m4f4dl6p6b21.s3.us-east-2.amazonaws.com/bundle.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className='wraperitem'>
      <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'./img/donate.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container-fluid'>
            <div className='row m-10-hor'>
              <div className='col-md-6'>
                <h1>Donate</h1>
              </div>
              <div className='col-md-6'>
                <div className='list'>
                  <a className='link' href="/">Home</a>
                  <span className='dash'>/</span>
                  <span>Donate</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container-fluid' id="service">
        <div className='row m-10-hor'>
          <div className='col-md-8'>
            <div className="wrap-donorbox_hadith">
              <div className="text-size-medium">The Prophet Muhammad (SAWS) said: </div>
              <div className="text-block">
                <strong>إِذَا مَاتَ الْإِنْسَانُ انْقَطَعَ عَنْهُ عَمَلُهُ إِلَّا مِنْ ثَلَاثَةٍ صَدَقَةٍ جَارِيَةٍ أَوْ عِلْمٍ يُنْتَفَعُ بِهِ أَوْ وَلَدٍ صَالِحٍ يَدْعُو لَهُ</strong>
              </div>
              <div className="text-size-small">
                “When a man dies, his deeds come to an end except for three things: Sadaqah Jariyah (ceaseless charity); a knowledge which is beneficial, or a virtuous descendant who prays for him (for the deceased).”
                (Muslim)
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div data-entity="EMBED_FORM" data-source="CharityStack" id="fd2450d2-e981-4604-bee4-979896a6b5ac"></div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Donate;
