import React, { Component } from 'react';
import { Link } from '@reach/router';
import Slider from 'react-slick';
import Footer from '../../components/footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomSlide from '../../components/ImageGallery/slides';


class Images extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 5000,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    };

    const imageNames = [
      '1.jpg',
      '2.jpg',
      '3.jpg',
      '4.jpg',
      '5.jpg',
      '6.jpg',
      '7.jpg',
      '8.jpg',
      '9.jpg',
      '10.jpg',
      '11.jpg',
      '12.jpg',
    ];

    return (
      <div className='slickteam'>
        <Slider {...settings}>
          {imageNames.map((imageName, index) => (
            <CustomSlide
              className='itm'
              index={index}
              key={index}
              imgSrc={`/img/gallery/6housing/${imageName}`}
            />
          ))}
        </Slider>
      </div>
    );
  }
}

export default () => (
  <div className='wrapperitem'>
    <section className='jumbotron breadcumb' style={{ position: 'relative', overflow: 'hidden', zIndex: 0 }}>
      <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/gallery/6housing/12.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(3px)',
          zIndex: -2,
        }}>
      </div>
      <div className='mainbreadcumb' style={{ position: 'relative', zIndex: 1 }}>
        <div className='container-fluid'>
          <div className='row m-10-hor'>
            <div className='col-md-6'>
              <h1>Housing Project</h1>
            </div>
            <div className='col-md-6'>
              <div className='list'>
                <Link className='link' to="/">Home</Link>
                <span className='dash'>/</span>
                <Link className='link' to="/projects">Projects</Link>
                <span className='dash'>/</span>
                <span>Housing Project</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='container-fluid' id="service">
      <div className='row m-10-hor'>
        <div className='col-md-4'>
        <a href="b38a6bf1ab2" id="amanahfy-widget">Housing Crowd Funding</a>
        </div>
        <div className='col-md-8'>
          <Images />
        </div>
      </div>
    </section>
    <Footer />
  </div>
);
