import React, { Component } from 'react';
import { Link } from '@reach/router';
import Footer from '../../components/components/footer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


class CustomSlide extends Component {
  handleVideoPlay = () => {
    // When video plays, call the callback to pause the slider
    this.props.onVideoPlay && this.props.onVideoPlay();
  };

  handleVideoPause = () => {
    // When video pauses, call the callback to resume the slider
    this.props.onVideoPause && this.props.onVideoPause();
  };

  renderMedia() {
    const { fileName, index } = this.props;
    if (!fileName) {
      return <div>Loading...</div>;
    }

    const basePath = '/img/gallery/11zakat/';
    const fileExtension = fileName.split('.').pop().toLowerCase();

    if (fileExtension === 'mp4' || fileExtension === 'webm') {
      return (
        <video className="img-fluid" controls style={{ width: '100%', height: '650px', objectFit: 'cover' }}
          onPlay={this.handleVideoPlay}
          onPause={this.handleVideoPause}
          onEnded={this.handleVideoPause} // Consider the video as paused when it ends
        >
          <source src={`${basePath}${fileName}`} type={`video/${fileExtension}`} />
          Your browser does not support the video tag.
        </video>
      );
    }

    // Assume everything else is an image
    return (
      <img src={`${basePath}${fileName}`} className="img-fluid" alt={`Slide ${index}`} style={{ width: '100%', height: '650px', objectFit: 'cover' }} />
    );
  }

  render() {
    return (
      <div {...this.props}>
        {this.renderMedia()}
      </div>
    );
  }
}


class Images extends Component {
  slider = React.createRef(); // Create a ref for the slider

  handleVideoPlay = () => {
    this.slider.current.slickPause(); // Pause slider autoplay
  };

  handleVideoPause = () => {
    this.slider.current.slickPlay(); // Resume slider autoplay
  };
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 5000,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    };

    const imageNames = [
      '6.mp4',
      '1.jpeg',
      '2.jpeg',
      '3.jpeg',
      '4.jpeg',
      '5.jpeg',
    ];

    return (
      <div className='slickteam'>
        <Slider {...settings} ref={this.slider}>
          {imageNames.map((filePath, index) => (
            <CustomSlide
              className='itm'
              index={index}
              key={index}
              fileName={filePath}
              onVideoPlay={this.handleVideoPlay}
              onVideoPause={this.handleVideoPause}
            />
          ))}
        </Slider>
      </div>
    );
  }
}

export default () => (
  <div className='wrapperitem'>
    <section className='jumbotron breadcumb' style={{ position: 'relative', overflow: 'hidden', zIndex: 0 }}>
      <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/gallery/11zakat/zakat.jpeg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(2px)',
          zIndex: -2,
        }}>
      </div>
      <div className='mainbreadcumb' style={{ position: 'relative', zIndex: 1 }}>
        <div className='container-fluid'>
          <div className='row m-10-hor'>
            <div className='col-md-6'>
              <h1>Zakat Al-Fitr</h1>
            </div>
            <div className='col-md-6'>
              <div className='list'>
                <Link className='link' to="/">Home</Link>
                <span className='dash'>/</span>
                <span>Zakat Al-Fitr</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='container-fluid' id="service">
      <div className='row m-10-hor'>
      <div className='col-md-5'>
            <div className='heading'>
            Sharing the joy <span className='br'>of Eid</span>
            </div>
          </div>

          <div className='col-md-7 mb-3'>
            <div className='content'>
              Zakat al-Fitr is a special act of charity given by Muslims at the end of Ramadan. It's a way to ensure that everyone - regardless of their financial situation – can joyfully celebrate Eid al-Fitr, the festival marking the end of the fasting month. This small contribution makes a big difference in the lives of those in need.
            </div>
          </div>
          <div style={{width: '100%', height: '1px', backgroundColor: '#FFF', margin: '10px 0'}}></div>
        <div className='col-md-4'>
          <a href="29a08d63b24" id="amanahfy-campaign-widget">Zakat</a>
        </div>
        <div className='col-md-8'>
          <Images />
        </div>
      </div>
    </section>
    <Footer />
  </div>
);
