
import React from 'react';
import { Link } from '@reach/router';
import Footer from '../../components/footer';

export default () => (
  <div className='wrapperitem'>
    <section className='jumbotron breadcumb' style={{ position: 'relative', overflow: 'hidden', zIndex: 0 }}>
      <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/gallery/10closet/IMG_0146.JPG)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(3px)',
          zIndex: -2,
        }}>
      </div>
      <div className='mainbreadcumb' style={{ position: 'relative', zIndex: 1 }}>
        <div className='container-fluid'>
          <div className='row m-10-hor'>
            <div className='col-md-6'>
              <h1>Hope for Yemen</h1>
            </div>
            <div className='col-md-6'>
              <div className='list'>
                <Link className='link' to="/">Home</Link>
                <span className='dash'>/</span>
                <Link className='link' to="/events">Events</Link>
                <span className='dash'>/</span>
                <span>Hope for Yemen</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='container-fluid' id="ticket">
      <div className="ticket">
            <script src='https://s3.amazonaws.com/ticketbud/widgets/tickets.v5.js' className='ticketbud tickets' data-uuid='10211494-8ce0-11ee-8a2a-42010a71702b' data-host='ticketbud.com' async></script>
            <iframe 
              style={{ minHeight: '485px', border:'0'}}
              id="id" title='Hope for Yemen Event Registration' 
              src="//ticketbud.com/events/e1af9054-b408-11ee-b8a2-42010a71702b/external" 
              width="100%" height="300" vspace="0" hspace="0" 
              allowtransparency="true"
            >
            </iframe>    
        </div>
    </section>
    <Footer />
  </div>
);

