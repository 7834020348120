import React, { useEffect, useRef } from 'react';
import { Link } from '@reach/router';
import Footer from '../components/footer';
import '@fortawesome/fontawesome-free/css/all.min.css';

// Upcoming events metadata
const UPCOMING_EVENTS = [
  {
    img: "./img/events/annual_gala_2025.png",
    title: "UHF Annual Gala | 2025",
    datetime: "Monday February 17th | 6:00 PM",
    description: "Join us for the UHF Annual Gala. This past years was unforgettable, and next year promises to be even better. Stay tuned for the registration link!",
    buttonLabel: "Stay Tuned!",
    link: "/events",
    buttonStyle: {color:'#c7ab5a'}
  }
];

// Passed events metadata
const PASSED_EVENTS = [
  {
    img: "./img/events/CRSDxUHF.jpg",
    title: "Hits For Humanity | Women's Charity Volleyball Tournament",
    datetime: "Friday February 23rd | 6:00 PM - 9:00 PM",
    description: "Join us for a fun night of volleyball and fundraising for the people of Yemen!",
    link: "/events/hitsforhumanity",
    buttonLabel: "Sign up here!"
  },
  {
    img: "./img/events/RamadanBazaar.jpeg",
    title: "Women's Ramadan Bazaar",
    datetime: "Saturday February 24nd | 1:00 PM - 7:00 PM",
    description: "Join us for a fun day of shopping and fundraising for the people of Yemen! Interested in being a vendor?",
    link: "/contact",
    buttonLabel: "Contact us!"
  },
  {
    img: "./img/events/annual_gala.jpg",
    title: "Hope For Yemen Gala | 2024",
    datetime: "Monday February 19th | 6:00 PM",
    description: "The hope for Yemen Gala is an event dedicated to raising awareness and funds for the people of Yemen.",
    link: "/events/hopeforyemen",
    buttonLabel: "Register here!",
    buttonStyle: {color:'#c7ab5a'}
  },
  {
    img: "./img/events/gala2023.png",
    title: "The Giving Gala UHF | 2023",
    datetime: "October 28, 2023 | 07:30 - 10:00 PM",
    description: "The UHF Giving Gala, an all-women fundraiser dedicated to supporting the Housing Project in Yemen.",
  }
];

export default function Events() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const elements = elementsRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
            observer.unobserve(entry.target); // Stop observing once the animation is applied
          }
        });
      },
      { threshold: 0.1 }
    );

    elements.forEach((element) => {
      element.classList.add('hidden'); // Ensure elements are hidden initially
      observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  return (
    <div className='wrapperItem'>
      <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'./img/event.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container-fluid'>
            <div className='row m-10-hor'>
              <div className='col-md-6'>
                <h1>Events</h1>
              </div>
              <div className='col-md-6'>
                <div className='list'>
                  <Link className='link' to="/">Home</Link>
                  <span className='dash'>/</span>
                  <span>Events</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container-fluid' id="events" style={{paddingBottom: '0px'}} >
        <div className='row m-10-hor'>
          {/* Upcoming Events */}
          <div className='col-md-8' id="newevents">
            <h3>Current Events</h3>
            <ul className='recent-post'>
              {UPCOMING_EVENTS.map((event, index) => (
                <li key={`upcoming-${index}`} ref={(el) => (elementsRef.current[2 + index] = el)}>
                  <Link className='recent' to={event.link}>
                    <div className='img'>
                      <img src={event.img} alt="Event" />
                    </div>
                    <div className='contblog'>
                      <div className='title' style={{fontSize: '25px'}}>{event.title}</div>
                      <div className='datetime'>{event.datetime}</div>
                      <div className='descblog'>
                        {event.description}
                        <div style={{width: '100%', height: '1px', backgroundColor: '#ffffff', margin: '30px 0px'}}></div>
                        <button className='buttonEvent' style={event.buttonStyle || {}}><span>{event.buttonLabel}</span><i></i></button>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Passed Events */}
          <div className='col-md-4'>
            <h3 style={{opacity: '0.5'}}>Passed Events</h3>
            <ul className='recent-post'>
              {PASSED_EVENTS.map((event, index) => (
                <React.Fragment key={`passed-${index}`}>
                  <div className='img' style={{width: '100%', height: '200px', overflow: 'hidden', padding: '10px'}} ref={(el) => (elementsRef.current[2 + UPCOMING_EVENTS.length + index] = el)}>
                    <img src={event.img} alt="Event" />
                  </div>
                  <li ref={(el) => (elementsRef.current[2 + UPCOMING_EVENTS.length + PASSED_EVENTS.length + index] = el)}>
                    <div className='contblog'>
                      <div className='title' style={{fontSize: '20px', textDecoration: 'line'}}>{event.title}</div>
                      <div className='datetime'>{event.datetime}</div>
                      <div className='descblog'>{event.description}</div>
                    </div>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
