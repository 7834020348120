import React, { useState, useEffect } from 'react';

const Counter = ({ end, duration = 2000, isCurrency = false }) => {
    const [count, setCount] = useState(0);

    const formatNumber = (num) => {
        if (isCurrency) {
            // Convert to currency format
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(num);
        }
        // Return normal number format without decimals for non-currency
        return Math.floor(num).toLocaleString();
    };

    useEffect(() => {
        let start = 0;
        const increments = 100;
        const incrementTime = duration / increments;

        const timer = setInterval(() => {
            start += end / increments;
            setCount(start);
            if (start >= end) {
                setCount(end);
                clearInterval(timer);
            }
        }, incrementTime);

        return () => clearInterval(timer);
    }, [end, duration]);

    return <span>{formatNumber(count)}</span>;
};

export default Counter;
