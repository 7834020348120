import React, { useEffect, useRef } from "react";
import { navigate } from "@reach/router";
import ImageMosaic from "./components/ImageMosaic";
import images from "./images";

const ImageGallery = () => {
  const elementsRef = useRef([]);

  useEffect(() => {
    const elements = elementsRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
            observer.unobserve(entry.target); // Stop observing once the animation is applied
          }
        });
      },
      { threshold: 0.1 }
    );

    elements.forEach((element) => {
      if (element) {
        element.classList.add('hidden'); // Ensure elements are hidden initially
        observer.observe(element);
      }
    });

    return () => {
      elements.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  const handleClick = (slug) => {
    navigate(`/projects/${slug}`);
  };

  return (
    <>
      <ImageMosaic
        images={images}
        handleClick={(e, { index }) => {
          const projectSlug = images[index].slug;
          handleClick(projectSlug);
        }}
        elementsRef={elementsRef}
      />
    </>
  );
};

export default ImageGallery;
