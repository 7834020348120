
import React from 'react';
import { Link } from '@reach/router';
import Footer from '../components/footer';

export default () => (
  <div className='wrapperitem'>
    <section className='jumbotron breadcumb' style={{ position: 'relative', overflow: 'hidden', zIndex: 0 }}>
      <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/404.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(3px)',
          zIndex: -2,
        }}>
      </div>
      <div className='mainbreadcumb' style={{ position: 'relative', zIndex: 1 }}>
        <div className='container-fluid'>
          <div className='row m-10-hor'>
            <div className='col-md-6'>
              <h1>404 - Page not found</h1>
            </div>
            <div className='col-md-6'>
              <div className='list'>
                <Link className='link' to="/">Home</Link>
                <span className='dash'>/</span>
                <span>404</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='container-fluid' id="ticket">
        <div className='row m-10-hor'>
            <div className='col-md-12'>
              <h2>Sorry, the page you are looking for does not exist.</h2>
              <p>
              Perhaps you typed the link incorrectly. You can go back to the <Link to="/">homepage</Link> or contact us at 
              <a href='mailto:info@uhfrelief.org'> info@uhfrelief.org</a> if you think there is a mistake.
              </p>
            </div>
        </div>
    </section>
    <Footer />
  </div>
);
