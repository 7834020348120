import React, { useEffect, useRef } from 'react';
import { useLocation } from '@reach/router';
import Slider from '../components/sliderhome';
import Carouselteam from '../components/carouselteam';
import Footer from '../components/footer';
import '@fortawesome/fontawesome-free/css/all.min.css';
import useScript from '../components/useScript';
// import HomePopup from '../../components/components/home_popup';

const Home = () => {
  const elementsRef = useRef([]);
  const location = useLocation();
  const instagramScriptLoaded = useScript('//www.instagram.com/embed.js');

  useEffect(() => {
    const elements = elementsRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
            observer.unobserve(entry.target); // Stop observing once the animation is applied
          }
        });
      },
      { threshold: 0.1 }
    );

    elements.forEach((element) => {
      if (element) {
        element.classList.add('hidden'); // Ensure elements are hidden initially
        observer.observe(element);
      }
    });

    return () => {
      elements.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  useEffect(() => {
    if (instagramScriptLoaded) {
      window.instgrm.Embeds.process();
    }
    const beholdElement = document.querySelector('[data-behold-id="01QF4xY2rhLzR1r4Hscs"]');
    if (beholdElement) {
      beholdElement.innerHTML = ''; // Clear existing content
      const beholdWidget = document.createElement('behold-widget');
      beholdWidget.setAttribute('feed-id', '01QF4xY2rhLzR1r4Hscs');
      beholdElement.appendChild(beholdWidget);

      const beholdScript = document.createElement('script');
      beholdScript.type = 'module';
      beholdScript.src = 'https://w.behold.so/widget.js';
      beholdElement.appendChild(beholdScript);
    }
  }, [instagramScriptLoaded, location.pathname]);

  return (
    <div>
      {/* <HomePopup /> */}
      <section className="jumbotron jumbomain" ref={(el) => (elementsRef.current[0] = el)}>
        <Slider />
        <div className="icon-scroll-wraper">
          <div className="icon-scroll">
            <div className="icon-scroll-screen"></div>
          </div>
        </div>
      </section>

      {/* <section className='container-fluid p-0' ref={(el) => (elementsRef.current[2] = el)}>
        <div className='row'>
          <div className='col-md-12 p-0'>
            <div className='embed-container' style={{ border: '1px solid #ccc', padding: '20px' }}>
              <div data-behold-id="01QF4xY2rhLzR1r4Hscs"></div>
              </div>
            </div>
        </div>
      </section> */}

      <section className='container-fluid black pb-0' ref={(el) => (elementsRef.current[3] = el)}>
        <div className='row m-10-hor'>
          <div className='col-md-5'>
            <div className='heading'>
              Why <span className='br'></span>donate to<span className='br'></span> us?
            </div>
          </div>
          <div className='col-md-7'>
            <div className='content'>
              Donating to us offers a meaningful way to make a direct impact on the lives of those who are less fortunate. As a non-profit organization, UHF is committed to alleviating poverty, generating economic opportunities, and providing emergency relief at both local and international scales. 
            </div>
            <br></br>
            <div className='content'>
              Your contribution helps in the realization of a world where all communities are empowered and have access to basic human needs, from essential services to educational opportunities.
            </div>
          </div>
        </div>
      </section>

      <section className='container-fluid pt-0 black' ref={(el) => (elementsRef.current[4] = el)}>
        <div className='row m-10-hor'>
          <div className='col-md-4 mt-5'>
            <div className='col-feature'>
              <div className='sub-color text-gradient'>01.</div>
              <div className='heading'>HUMANITARIAN FOCUS</div>
              <div className='content'>
                UHF concentrates on alleviating poverty and providing emergency relief to communities in need. Whether locally or internationally, your support helps UHF make a meaningful impact in areas requiring urgent assistance.
              </div>
            </div>
          </div>
          <div className='col-md-4 mt-5'>
            <div className='col-feature'>
              <div className='sub-color text-gradient'>02.</div>
              <div className='heading'>ACCOUNTABLE TEAM</div>
              <div className='content'>
                Staffed by a dedicated and accountable team, UHF ensures that every donation is effectively channeled into efforts that align with their mission. Your contributions are transparently managed for maximum impact.
              </div>
            </div>
          </div>
          <div className='col-md-4 mt-5'>
            <div className='col-feature'>
              <div className='sub-color text-gradient'>03.</div>
              <div className='heading'>SUSTAINABLE DEVELOPMENT</div>
              <div className='content'>
                UHF not only aims for immediate relief but also invests in long-term solutions. Through initiatives that create economic opportunities and education, the organization promotes sustainable development within vulnerable communities.
              </div>
            </div>
          </div>
          <div className='col-md-4 mt-5'>
            <div className='col-feature'>
              <div className='sub-color text-gradient'>04.</div>
              <div className='heading'>EDUCATIONAL OPPORTUNITIES</div>
              <div className='content'>
                Education is one of UHF's pillars for community empowerment. By providing educational resources and programs, UHF is opening doors for individuals to better their own lives and communities.
              </div>
            </div>
          </div>
          <div className='col-md-4 mt-5'>
            <div className='col-feature'>
              <div className='sub-color text-gradient'>05.</div>
              <div className='heading'>EMERGENCY RELIEF</div>
              <div className='content'>
                UHF is adept at mobilizing resources quickly for immediate emergency relief. Whether natural disasters or humanitarian crises, the organization acts swiftly to provide essential supplies and services to those most affected.
              </div>
            </div>
          </div>
          <div className='col-md-4 mt-5'>
            <div className='col-feature'>
              <div className='sub-color text-gradient'>06.</div>
              <div className='heading'>GLOBAL REACH</div>
              <div className='content'>
                With operations at local, national, and international levels, UHF's impact is felt globally. Your contribution helps to address pressing issues in a multitude of contexts, broadening the scope of positive change.
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className='container-fluid p-0' ref={(el) => (elementsRef.current[1] = el)}>
        <div className='row'>
          <div className='col-md-12 p-0 text-center' style={{padding: '20px 0', border: '1px solid #ccc', marginBottom: '20px'}}>
            <a className='instagram-link' href="https://www.instagram.com/uhfrelief/" target="_blank" rel="noreferrer noopener">Follow Us on Instagram</a>
            <div className="insta-feed">
                <blockquote className="instagram-media" data-instgrm-permalink="https://www.instagram.com/uhfrelief/?utm_source=ig_embed&amp;utm_campaign=loading" data-instgrm-version="14" 
                style={{ background:'#FFF', border:0, borderRadius:'3px', boxShadow:'0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)', margin:'1px', maxWidth:'540px', minWidth:'326px', padding:0,  width:'calc(100% - 2px)'}}>
                </blockquote>
            </div>
          </div>
        </div>
      </section>


      <section className='container-fluid black_more' ref={(el) => (elementsRef.current[5] = el)}>
        <div className='row m-10-hor'>
          <div className='col-12 text-center'>
            <div className='subheading'>Our team</div>
            <div className='heading'>About Our Team</div>
            <div className='row'>
              <div className='col-md-8 mx-auto'>
                <p className='content'>
                  The dedicated team at UHF brings a wealth of expertise and commitment to humanitarian efforts. Each member is passionately focused on alleviating poverty, delivering emergency relief, and fostering sustainable development. The team's diverse skill sets allow us to adapt to various challenges, ensuring that resources are effectively utilized to bring about meaningful change in communities locally and globally.
                </p>
              </div>
            </div>
          </div>
          <Carouselteam />
          <div className='col-12 text-center'>
            <div className='subheading'></div>
            <a href="/team">
              <div className='btn'>
                <span className="shine" style={{ left: '-200px' }}></span>
                <span>Check out the rest of our team!</span>
              </div>
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
