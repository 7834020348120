import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 5000,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className='slickteam'>
        <Slider {...settings}>

        <CustomSlide className='itm' index={1}>
            <div className='bg'>
              <img
                src="./img/team/shareefa_albanna.jpeg"
                className="img-fluid"
                alt="Imageteam"
                style={{ width: '400px', height: '400px', objectFit: 'cover' }}
              />
            </div>
            <div className='desc'>
              <div className='name'>Shareefa Albanna</div>
              <div className='title'>Economic Development <br></br>Consultant/ Philanthropist</div>
            </div>
          </CustomSlide>

          <CustomSlide className='itm' index={2}>
            <div className='bg'>
              <img
                src="./img/team/bilal_hammoud.jpeg"
                className="img-fluid"
                alt="Imageteam"
                style={{ width: '400px', height: '400px', objectFit: 'cover' }}

              />
            </div>
            <div className='desc'>
              <div className='name'>Bilal Hammoud</div>
              <div className='title'>Education & Public Health Advocate</div>
            </div>
          </CustomSlide>

          <CustomSlide className='itm' index={3}>
            <div className='bg'>
              <img
                src="./img/team/davine_el_amin.jpg"
                className="img-fluid"
                alt="Imageteam"
                style={{ width: '400px', height: '400px', objectFit: 'cover' }}
              />
            </div>
            <div className='desc'>
              <div className='name'>Sr. Davine El-Amin</div>
              <div className='title'>Community Activist Diversity & Inclusion Liason</div>
            </div>

          </CustomSlide>

          <CustomSlide className='itm' index={4}>
            <div className='bg'>
              <img
                src="./img/team/amal_qayed.png"
                className="img-fluid"
                alt="Imageteam"
                style={{ width: '400px', height: '400px', objectFit: 'cover' }}

              />
            </div>
            <div className='desc'>
              <div className='name'>Amal Qayed</div>
              <div className='title'> Support Coordinator-Communitiess In Schools of Michigan</div>
            </div>
          </CustomSlide>

          <CustomSlide className='itm' index={5}>
            <div className='bg'>
              <img
                src="./img/team/mohannad_hakeem.jpeg"
                className="img-fluid"
                alt="Imageteam"
                style={{ width: '400px', height: '400px', objectFit: 'cover' }}
              />
            </div>
            <div className='desc'>
              <div className='name'>Dr. Mohannad Hakeem</div>
              <div className='title'>Consultant & Research
              <br></br>Engineer/ Author & Imam</div>
            </div>
          </CustomSlide>
          
          <CustomSlide className='itm' index={6}>
            <div className='bg'>
              <img
                src="./img/team/mariam_charara.png"
                className="img-fluid"
                alt="Imageteam"
                style={{ width: '400px', height: '400px', objectFit: 'cover' }}
              />
            </div>
            <div className='desc'>
              <div className='name'>Mariam Charara</div>
              <div className='title'>ACRL Executive Director</div>
            </div>
          </CustomSlide>   
        </Slider>
      </div>
    );
  }
}
