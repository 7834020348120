import React, { useState, useEffect } from 'react';
import Footer from '../components/footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import CheckoutModal from '../components/checkoutmodal';

const imageStyle = {
  width: '80%',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
  verticalAlign: 'middle',
};

const ShopItem = ({ item, onAddToCart }) => (
  <section className="card-shop">
    <div className="product-image">
      <img src={item.image} alt={item.name} style={imageStyle} />
    </div>
    <div className="product-info">
      <h5 className="item-name">{item.name}</h5>
      <h6 style={{ color: 'black' }}>${item.price}</h6>
      <button className="addcartButton" onClick={() => onAddToCart(item)}>Add to Cart</button>
    </div>
  </section>
);

const ShopItems = ({ onAddToCart }) => {
  const items = [
    { id: 1, name: 'Palestine Crewneck', brand: 'UHF', price: 30, image: './img/shop/shop2.png' },
    { id: 2, name: 'UHF Crewneck', brand: 'UHF', price: 25, image: './img/shop/shop3.png' },
    { id: 3, name: 'This Is Our Home', brand: 'Sara Alduais', price: 15, image: './img/shop/shop1.png' },
  ];

  return (
    <div className="container-shop">
      {items.map(item => (
        <ShopItem key={item.id} item={item} onAddToCart={onAddToCart} />
      ))}
    </div>
  );
};

const CartItem = ({ item, onRemoveFromCart, onIncrease, onDecrease }) => (
  <div className="cart-item">
    <p>{item.name}: ${item.price} x {item.count}</p>
    <div className="item-quantity-controls">
      <button className="remove-item-btn" onClick={() => onDecrease(item.id)}>-</button>
      <button className="add-item-btn" onClick={() => onIncrease(item.id)}>+</button>
    </div>
  </div>
);

const CartPage = ({ cart, onRemoveFromCart, onIncrease, onDecrease }) => {
  return (
    <div className="cart-page">
      <h2>Your Cart</h2>
      {cart.length > 0 ? (
        <div>
          {cart.map((item, index) => (
            <CartItem
              key={index}
              item={item}
              onIncrease={onIncrease}
              onDecrease={onDecrease}
            />
          ))}
        </div>
      ) : (
        <p>Your cart is empty.</p>
      )}
    </div>
  );
};


export default function ShoppingCartApp() {
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);

  const openCheckout = () => setIsCheckoutOpen(true);
  const closeCheckout = () => setIsCheckoutOpen(false);
  const [cart, setCart] = useState([]);
  const [showCart, setShowCart] = useState(false); // New state to toggle cart display
  const totalItemCount = cart.reduce((total, item) => total + (item.count || 1), 0);

  useEffect(() => {
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
      setCart(JSON.parse(storedCart));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const handleIncrease = (itemId) => {
    setCart((currentCart) =>
      currentCart.map((item) =>
        item.id === itemId ? { ...item, count: (item.count || 1) + 1 } : item
      )
    );
  };
  
  const handleDecrease = (itemId) => {
    setCart((currentCart) => {
      // Find the item in the cart
      const itemIndex = currentCart.findIndex(item => item.id === itemId);
      if (itemIndex >= 0) {
        const newItemCount = currentCart[itemIndex].count - 1;
        
        // If item count goes below 1, remove it from the cart
        if (newItemCount < 1) {
          return currentCart.filter(item => item.id !== itemId);
        } else {
          // Otherwise, update the item count
          return currentCart.map((item, index) =>
            index === itemIndex ? { ...item, count: newItemCount } : item
          );
        }
      }
      return currentCart;
    });
  };
  
  const handleAddToCart = (itemToAdd) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex((item) => item.id === itemToAdd.id);
      if (existingItemIndex >= 0) {
        const newCart = [...prevCart];
        newCart[existingItemIndex] = {
          ...newCart[existingItemIndex],
          count: (newCart[existingItemIndex].count || 1) + 1,
        };
        return newCart;
      } else {
        return [...prevCart, { ...itemToAdd, count: 1 }];
      }
    });
  };
  
  const toggleCart = () => {
    setShowCart(!showCart);
  };

  const handleRemoveFromCart = (itemId) => {
    setCart((currentCart) => currentCart.filter(item => item.id !== itemId));
  };
  
  const handleClearCart = () => {
    setCart([]);
  };

  const handleCheckout = async (formData) => {
    const orderDetails = {
      name: formData.name,
      street: formData.street,
      city: formData.city,
      state: formData.state,
      zip: formData.zip,
      phoneNumber: formData.phoneNumber,
      email: formData.email,
      order: cart.map(item => ({ name: item.name, count: item.count })),
    };
  
    try {
      await fetch('https://script.google.com/macros/s/AKfycbwe1vVp5ulrP0w4NuRK5sk-50JiArXCIcO8kaO4FhhYa6glQQkOUiBGyrRxodQgxPZ3VA/exec', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderDetails),
      });
  
      console.log('Order submitted successfully');
      setCart([]);
      closeCheckout(); 

    } catch (error) {
      console.error('Error submitting order:', error);
    }
  };

  return (
    <div className='wrapperitem'>
      <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'./img/shop.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container-fluid'>
            <div className='row m-10-hor'>
              <div className='col-md-6'>
                <h1>UHF Shop</h1>
              </div>
              <div className='col-md-6'>
                <div className='list'>
                  <span>Home</span>
                  <span className='dash'>/</span>
                  <span>UHF Shop</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container-fluid' id="shop" style={{paddingBottom: '0px'}}>
        <div className='row m-10-hor'>
            <div className='col-md' id="newproducts">
              <button onClick={toggleCart} className="addcartButton"
                style={{alignContent: 'center', margin: 'auto', display: 'block', marginBottom: '20px', backgroundColor: 'white', color: 'black'}}
              >Cart {totalItemCount} Items</button>
              {showCart ? (
                <CartPage
                  cart={cart}
                  onRemoveFromCart={handleRemoveFromCart}
                  onIncrease={handleIncrease}
                  onDecrease={handleDecrease}
                  onClearCart={handleClearCart}
                />
              ) : (
                <ShopItems onAddToCart={handleAddToCart} />
              )}
              <div className="checkout-button-container">
                <button onClick={openCheckout} 
                  className="checkout-btn"  style={{alignContent: 'center', margin: 'auto', display: 'block', marginBottom: '20px', color: 'black'}}>
                    Proceed to Checkout
                </button>
                <CheckoutModal
                  isOpen={isCheckoutOpen}
                  onClose={closeCheckout}
                  onCheckout={handleCheckout}
                />
              </div>
            </div>
            
          </div>
      </section>

      <Footer/>

    </div>
  );
}

