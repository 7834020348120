
import React, { useEffect } from 'react';
import { Link, navigate } from '@reach/router';
import Footer from '../components/footer';

export default () => {
  useEffect(() => {
    const correctPassword = 'uhftest';
    const userPassword = prompt('Please enter the password to access this page:');

    if (userPassword !== correctPassword) {
      alert('Incorrect password!');
      navigate('/');
    }
  }, []);

  return (
    <div className='wrapperitem'>
    <section className='jumbotron breadcumb' style={{ position: 'relative', overflow: 'hidden', zIndex: 0 }}>
      <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/404.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(3px)',
          zIndex: -2,
        }}>
      </div>
      <div className='mainbreadcumb' style={{ position: 'relative', zIndex: 1 }}>
        <div className='container-fluid'>
          <div className='row m-10-hor'>
            <div className='col-md-6'>
              <h1>(Private) Testing Link</h1>
            </div>
            <div className='col-md-6'>
              <div className='list'>
                <Link className='link' to="/">Home</Link>
                <span className='dash'>/</span>
                <span>Test</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='container-fluid' id="">
        <div className='row m-10-hor'>
            <div className='col-md-12'>
              <h3>This page is meant for testing purposing. If you have reached here by mistake, please go back to the <Link to="/">homepage</Link>.</h3>
              <h3 style={{color: 'red'}}>Do not attempt to donate below unless you are authorized to do so.</h3>
            </div>

            <div style={{width: '100%', height: '1px', backgroundColor: '#FFF', margin: '10px 0'}}></div>

            <div className='col-md-12'>
              <a href="dd5ccc354dd" id="amanahfy-campaign-widget">A</a>
            </div>
        </div>
    </section>
    <Footer />
  </div>
);
}
  