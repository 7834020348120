import React, { useEffect, useRef } from 'react';
import { Link } from '@reach/router';
import posed from 'react-pose';
import Carouselteam from '../components/carouselteam';
import CarouselAdvisor from '../components/carouseladvisory';
import Footer from '../components/footer';
import '@fortawesome/fontawesome-free/css/all.min.css';

const ListContainer = posed.div({
  enter: { staggerChildren: 20 },
  exit: { staggerChildren: 20, staggerDirection: 0 }
});

const Item = posed.section({
  enter: { y: 0, opacity: 1 },
  exit: { y: 0, opacity: 0 }
});

const useIntersectionObserver = () => {
  const elementsRef = useRef([]);

  useEffect(() => {
    const elements = elementsRef.current; // Copy elementsRef.current to a variable

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
            observer.unobserve(entry.target); // Stop observing once the animation is applied
          }
        });
      },
      { threshold: 0.1 }
    );

    elements.forEach((element) => {
      if (element) {
        element.classList.add('hidden'); // Ensure elements are hidden initially
        observer.observe(element);
      }
    });

    return () => {
      elements.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return elementsRef;
};

export default () => {
  const elementsRef = useIntersectionObserver();

  return (
    <ListContainer>
      <Item
        className='jumbotron breadcumb'
        style={{ backgroundImage: `url(${'./img/team.jpg'})` }}
        ref={(el) => elementsRef.current[0] = el}
      >
        <div className='mainbreadcumb'>
          <div className='container-fluid'>
            <div className='row m-10-hor'>
              <div className='col-md-6'>
                <h1>Our Team</h1>
              </div>
              <div className='col-md-6'>
                <div className='list'>
                  <Link className='link' to="/">Home</Link>
                  <span className='dash'>/</span>
                  <span>Team</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Item>

      <Item className='container-fluid' ref={(el) => elementsRef.current[1] = el}>
        <div className='row m-10-hor'>
          <div className='col-md-5'>
            <div className='heading'>
              About Our<span className='br'></span> Team
            </div>
          </div>

          <div className='col-md-7 mb-3'>
            <div className='content'>
              The expertise of our team covers a wide range of humanitarian and development fields. Our collective experience allows us to tackle poverty, emergency relief, and sustainable growth in a multi-faceted way. Each member brings a unique skill set that contributes to our effectiveness and adaptability in addressing diverse challenges, from immediate crises to long-term development goals.
            </div>
          </div>

          <div style={{ width: '100%', height: '1px', backgroundColor: '#FFF', margin: '10px 0' }}></div>

          <div className="row m-10-hor">
            <div style={{ textAlign: 'center', fontSize: '24px', marginTop: '10px' }}>Board Members</div>
          </div>

          <div className='col-md-12' ref={(el) => elementsRef.current[2] = el}>
            <Carouselteam />
          </div>

          <div className='col-md-12'>
            <div style={{ textAlign: 'center', fontSize: '24px', color: 'transparent' }}></div>
            <div className='col-md-12'>
              <div style={{ textAlign: 'center', fontSize: '24px' }}>Advisory Board</div>
            </div>
          </div>
          
          <div className='col-md-12' ref={(el) => elementsRef.current[3] = el}>
            <CarouselAdvisor />
          </div>
        </div>
      </Item>

      <Footer />
    </ListContainer>
  );
};
